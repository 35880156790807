import React, { useEffect } from "react";
import {navigate} from 'gatsby';
import ReactDynamicImport from 'react-dynamic-import';
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/Schedule/ScheduleRecord')
    )
  } else {
    return (
      import('@components/Schedule/ScheduleRecordMobile')
    )
  }
}

const ScheduleRecord = ReactDynamicImport({ loader })

const ScheduleRecordPage = () => {
  useEffect(() => {
    navigate('/404');
  }, [])

  return <ScheduleRecord />
}

export default ScheduleRecordPage;
